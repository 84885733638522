<template>
	<!-- Rendered view start -->
	<router-view></router-view>
	<!-- Rendered view end -->
</template>

<script>

export default {
	components: {
		
	},
  mounted(){
    // const token = localStorage.getItem('eog-admins-accesstoken')
    // axios.post(process.env.VUE_APP_SERVER_URL + '/auth/admin/verifyAccessToken',1,
    // { headers:
    //     { 
    //         'Authorization': `Bearer ${token}`,
    //         'x-admin-key': process.env.VUE_APP_ADMIN_KEY,
    //     }
    // }
    // ).then((res)=>{
    //     if(res){
    //       // localStorage.setItem("eog-admins-accesstoken", res.data.accessToken)
    //     }
    // })
  }
}
</script>

<style>

html { 
  overflow-y: auto !important;
  overflow-x: auto !important 
}

@keyframes fadeIn {
    0%{
       opacity: 0;
       transform: translateZ(0);
    }
    100%{
       opacity: 1;
       transform: translateZ(100%)
    }
}

.checkFade {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

.animated{
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
}
</style>
