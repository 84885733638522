<template>
    <section class="h-screen z-50">
        <div class="h-full">
            <div class="flex flex-col justify-center items-center">
                <!-- <img loading="lazy" src="../assets/oeg-800.png" class="w-[300px] mt-32" alt="oeg"> -->
                <div class="text-white text-[30px] mx-auto" style="font-family: Acme, sans-serif; line-height: 0.8;">Getting User Information</div>
                <div class="mt-14 w-24 h-24 rounded-full animate-spin
                    border-y-8 border-solid border-purple-500 border-t-transparent">
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>

</style>